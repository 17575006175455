/* App.scss */
$app-bg-color: #f4f4f4;
$input-bg-color: #ffffff;
$button-bg-color: #4caf50;
$button-text-color: #ffffff;
$response-text-color: #333333;

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $app-bg-color;
}

.input-box {
  width: 300px;
  /* Set initial width */
  min-height: 100px;
  /* Set initial height */
  max-width: 100%;
  /* Allow box to grow horizontally */
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: $input-bg-color;
  resize: both;

  /* Allow box to grow vertically */
  &:focus {
    outline: none;
    border-color: $button-bg-color;
  }
}

.submit-button {
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  background-color: $button-bg-color;
  color: $button-text-color;
  cursor: pointer;

  &:hover {
    background-color: darken($button-bg-color, 10%);
  }
}

.response {
  font-size: 18px;
  color: $response-text-color;
  margin-top: 20px;
  max-width: 800px;
  line-height: 1.5em;
  margin-left: min(10%, 200px);
  margin-right: min(10%, 200px);
}

.loading-spinner {
  margin-top: 20px;
}